@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
    --dddddd: #ddd;
    --1e1e1f: #1e1e1f;
    --d0d0d0: #d0d0d0;
    --black: black;
    --accent-c0bfba: #c0bfba;
    --9a9ca0: #9a9ca0;
    --white: white;
}
#root{
    display: flex;
    flex-direction: column;
}
*, :after, :before {
    box-sizing: border-box
}
html{
    scroll-behavior: smooth;
}
.div, .h1, .h2, .h3, .h4, .h5, .h5-alt, .p, .span, div, h1, h2, h3, h4, h5, p, span {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img, svg {
    vertical-align: middle;
}

img {
    border-style: none;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section{
    display: block;
}

body{
    font-family: Roboto, sans-serif;
    background-color: black;
    color: white;
    overflow-x: hidden;
}

/*Nav*/
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
@media (min-width:768px) {
    .col-md-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }
    .col-md-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
    }
}
@media (min-width:992px) {
    .col-lg-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }
}
.d-flex {
    display: flex!important;
}
.d-none {
    display: none!important;
}
.justify-content-end {
    justify-content: flex-end!important;
}
.align-items-center {
    align-items: center!important;
}
a, a:hover {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    /*font-family: GT America,source-han-sans-simplified-c,sans-serif;*/
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
}
.Navigation.Navigation_black .Navigation_desktopNavigationWrap a {
    color: rgb(255, 255, 255);
}




.logo-wrap{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}


.Navigation {
    -webkit-user-select: none;
}
.Navigation .Navigation_desktopNavigationWrap {
    background-color: rgba(0,0,0,0.1);
    height: 120px;
    padding-right: 0px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    transition-duration: 0.35s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: all;
    backdrop-filter: blur(10px); /* Homályos üveg hatás */
    -webkit-backdrop-filter: blur(10px); /* Safari támogatás */

}


@media (min-width:768px) {
    .d-md-block {
        display: block !important;
    }
    .d-md-none {
        display: none !important;
    }
}

.DesktopNavigation {
    background-color: transparent;
    /*max-width: 1140px;*/
    margin-top: auto;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: all;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}


.DesktopNavigation .DesktopNavigation_navItem:not(:last-child) {
    margin-right: 32px;
}



.Navigation .Navigation_mobileNavigationWrap {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;
}


.MobileNavigation .MobileNavigation_navTop {
    width: 100%;
    height: 7.8vh;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    transition-duration: 0.35s, 0.35s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    transition-behavior: normal, normal;
    transition-property: background, top;
}
.MobileNavigation .MobileNavigation_navTop .MobileNavigation_mobLogo {
    display: flex;
    align-items: center;
    padding-left: 15px;
}
.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon {
    width: 7.8vh;
    height: 7.8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.35s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: background;
}
@media {
    .MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon {
        width: 120px;
        height: 102px;
        cursor: pointer;
    }
}
.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_boxContainer {
    width: 22px;
    position: relative;
    transition-duration: 0.35s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: transform;
    transform: rotate(0deg);
}
.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_box1 {
    width: 22px;
    position: absolute;
    top: calc(50% - 6px);
    left: 0px;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-image-source: none;
    border-image-slice: 100%;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: rgb(0, 0, 0);
}

.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_box2 {
    width: 22px;
    position: absolute;
    top: 50%;
    left: 0px;
    border-top-width: medium;
    border-right-width: medium;
    border-left-width: medium;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-left-color: currentcolor;
    border-image-source: none;
    border-image-slice: 100%;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(0, 0, 0);
}

.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_box3 {
    width: 22px;
    position: absolute;
    top: calc(50% + 6px);
    left: 0px;
    border-top-width: medium;
    border-right-width: medium;
    border-left-width: medium;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-left-color: currentcolor;
    border-image-source: none;
    border-image-slice: 100%;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgb(0, 0, 0);
}
.MobileNavigation.MobileNavigation_black .MobileNavigation_navTop .MobileNavigation_hamburgerIcon
.MobileNavigation_box1, .MobileNavigation.MobileNavigation_black .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon .MobileNavigation_box2, .MobileNavigation.MobileNavigation_black
.MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_box3,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6
.MobileNavigation_navTop .MobileNavigation_hamburgerIcon .MobileNavigation_box1,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6 .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon .MobileNavigation_box2,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6 .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon .MobileNavigation_box3 {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-image-source: none;
    border-image-slice: 100%;
    border-image-width: 1;
    border-image-outset: 0;
    border-image-repeat: stretch;
    background-color: rgb(0, 0, 0);
}
.MobileNavigation .MobileNavigation_navContent {
    transition-duration: 0.35s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: right;
    width: 100%;
    background-image: none;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
    background-origin: padding-box;
    background-clip: border-box;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    height: 100vh;
    position: absolute;
    right: -100%;
    top: 0px;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
}
.MobileNavigation .MobileNavigation_navContent .MobileNavigation_navContentTop {
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}
.MobileNavigation .MobileNavigation_navContent .MobileNavigation_navigationUrlWrap {
    padding-top: 112px;
    padding-right: 0px;
    padding-bottom: 64px;
    padding-left: 0px;
}
.MobileNavigation .MobileNavigation_navContent .MobileNavigation_navContentTop .MobileNavigation_navItem {
    margin-bottom: 16px;
    color: rgb(255, 255, 255);
    /*font-family: "GT America", source-han-sans-simplified-c, sans-serif;*/
    font-style: normal;
    font-feature-settings: "ss03";
    font-weight: 400;
    font-size: 31px;
    line-height: 43px;
}

.MobileNavigation.MobileNavigation_active {
    width: 100%;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
}
.MobileNavigation .MobileNavigation_navContent.MobileNavigation_active {
    overflow-y: scroll;
    transition-duration: 0.35s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: right;
    right: 0px;
}
.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box1 {
    width: 18px;
    top: 50%;
    transform: rotate(45deg);
}

.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box2 {
    width: 18px;
    top: 50%;
    transform: rotate(-45deg);
}

.MobileNavigation .MobileNavigation_navTop .MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box3 {
    display: none;
}

.MobileNavigation.MobileNavigation_black .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box1,
.MobileNavigation.MobileNavigation_black .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box2,
.MobileNavigation.MobileNavigation_black .MobileNavigation_navTop__ie_yG
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box3,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6 .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box1,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6 .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box2,
.MobileNavigation.MobileNavigation_theRoadAhead__OmwC6 .MobileNavigation_navTop
.MobileNavigation_hamburgerIcon.MobileNavigation_active .MobileNavigation_box3 {
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
}

/*Hero*/
div.HeroCover_heroCover__nk2lm {
    /*contain: strict;*/
    overflow-x: hidden;
    overflow-y: hidden;
    /*height: calc(-64px + 100vh);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    position: relative;
}

div.HeroCover_heroCover__nk2lm div.HeroCover_container__axkIp {
    padding-top: 102px;
}

div.HeroCover_glareContainer__LXAd_ {
    position: absolute;
    width: 140%;
    height: 140%;
    left: -20%;
    top: -75%;
}

svg.Glare_glare__dVsae {
    contain: strict;
    width: 100%;
    height: 100%;
}

div.HeroCover_heroCover__nk2lm h1 {
    /*font-family: frogSerif, source-han-serif-sc, serif;*/
    font-style: normal;
    font-weight: 300;
    font-feature-settings: "ss03";
    font-size: 10vw;
    line-height: 100%;
    padding-top: 150px;
}

div.HeroCover_container_blog{
    padding-top: 150px;
    padding-bottom: 60px;
}


div.HeroCover_heroCover__nk2lm p {
    /*font-family: "GT America", source-han-sans-simplified-c, sans-serif;*/
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-feature-settings: "ss03";
    margin-top: 80px;
    /*width: 60vw;*/
    padding-right: 20px;
}
.hero-p{
    width: 60vw;
}
div.HeroCover_heroCover__nk2lm div.HeroCover_cta__xC0dq {
    margin-top: 32px;
}

.CtaButton_ctaButtonWrap__VBSCf {
    display: inline-block;
}

.CtaButton_ctaButtonWrap__VBSCf .CtaButton_ctaButton__RXRzm.CtaButton_black__mGAd6 {
    color: rgb(255, 255, 255);
    cursor: pointer;
    outline-style: none;
    background-color: transparent;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    max-height: 26px;
}
.CtaButton_ctaButtonWrap__VBSCf .CtaButton_ctaButton__RXRzm .CtaButton_ctaButtonTitle__sPrtD {
    transition-duration: 0.45s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-behavior: normal;
    transition-property: all;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: left;
}

.CtaButton_ctaButtonWrap__VBSCf .CtaButton_ctaButton__RXRzm .CtaButton_arrow__eRjhQ.CtaButton_right__3l7dH {
    margin-left: 12px;
}

@media (min-width:992px){
    .offset-lg-6 {
        margin-left: 50%;
    }
}

@media (min-width:992px){
    .col-lg-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
}

@media (min-width:768px){
    .offset-md-4 {
        margin-left: 33.33333333%;
    }
}

@media (min-width:768px){
    .col-md-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
    }
}

@media (min-width:576px){
    .col-sm-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }
}

.col-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
}


@media (min-width:1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}

/*Service*/
div.ServiceCover_serviceCover {
    /*contain: strict;*/
    overflow-x: hidden;
    overflow-y: hidden;
    height: 120%;
    /*height: calc(-64px + 40vh);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    position: relative;
    padding: 4% 1%;
}

div.ServiceCover_serviceCover h2 {
    /*font-family: frogSerif, source-han-serif-sc, serif;*/
    font-style: normal;
    font-weight: 300;
    font-feature-settings: "ss03";
    font-size: 6.7vw;
    /*line-height: 80px;*/
    background-repeat: no-repeat;
    /*background-image: url("/assets/img/gradient.jpg");*/
    display: inline-block;
    background-size: cover;
    background-position: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}



/*how to work*/
.howtowork-section{
    background-color: #ffffff;
    color: black;
}
.howtowork-section .howtowork-header{
    position: relative;
    background-color: #dddddd;
    padding: 12vw 1.2vw 1.2vw;
    font-size: 4.66vw;
}

.howtowork-description{
    font-size: 2.5vw;
    padding: 1.2vw 1.2vw 1.2vw;
}

.grid-container {
    padding: 1%;
    display: grid;
    gap: 10px;

}
.grid-item {
    width: 100%;
    /*background-color: #3498db;*/
    color: black;
    padding: 20px;
    font-size: 1.2rem;
    border-radius: 5px;
}
.howtowork-section .item-description{
    padding-top: 3%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.small-number {
    font-family: 'Be Vietnam Pro', arial, sans-serif;
    color: #111111;
    font-size: 3em;
    font-weight: 900;
    line-height: 1;
    text-decoration: underline;
    letter-spacing: -0.03rem;
}
.
@media screen {
    @media (min-width: 1680px) {
        .spacer-xs {
            height: 1rem;
        }
    }
}



/* Mobil (1 oszlop) */
.grid-container {
    grid-template-columns: 1fr;
}
/* Tablet (2 oszlop) */
@media (min-width: 600px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
/* Asztali (3 oszlop) */
@media (min-width: 900px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/*faq*/
.faq-section {
    background-color: #ffffff;
    color: #111111;
}
.faq-section .faq-header{
    position: relative;
    background-color: #dddddd;
    padding: 12vw 1.2vw 1.2vw;
    font-size: 4.66vw;
}
.faq-header h2 {

}
.faq-content{
    padding-bottom: 11vw;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.faq-item {
    border-bottom: 1px solid #ddd;
}

.faq-question {
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq-icon {
    font-size: 16px;
}

.faq-answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 2s ease-in-out, opacity 0.5s ease-in-out;
    background-color: #f9f9f9;
    padding: 0 15px;
}

.faq-item.open .faq-answer {
    max-height: 150px; /* Ha nagyon hosszú a szöveg, ezt növeld */
    opacity: 1;
    padding: 15px;
}


/*workwithus*/
.workwithus-section {
    position: relative;
    overflow: hidden;
    /*background-color: #ffffff;
    color: black;*/
}
.workwithus-section .workwithus-header{
    color: white;
    opacity: 1;
    font-size: 4.66vw;
    /*background-color: #dddddd;*/
    padding: 12vw 1.2vw 1.2vw;
}
.workwithus-header h2{
    transform: translate(0px, 0px);
    opacity: 1;
}

.marquee-img{
    scale: 0.5;
    margin: 40px;
    transition: filter 0.3s ease-in-out; /* Simább átmenet */
    filter: grayscale(100%);
}
.marquee-img:hover{
    filter: grayscale(0%);
}

/*references*/

#w-node-_4eea71c3-35e7-d01f-997f-912439d88256-414c16ec {
    grid-area: 1 / 1 / 2 / 6;
}
#w-node-a52dcbf1-9ec2-ed13-98a4-d72637d71f15-414c16ec {
    grid-area: 1 / 1 / 2 / 4;
}
#w-node-_6b427139-4477-535d-4454-a2bf65a19b7f-414c16ec, #w-node-_6b427139-4477-535d-4454-a2bf65a19b7f-414c16ec,
#w-node-b6852b0b-218a-194e-9b83-6f4d64ee456d-414c16ec, #w-node-bddb8431-8760-0334-0587-b45ea09037df-414c16ec,
#w-node-_2a99fc00-3174-6759-81ff-47a34d8e3726-414c16ec, #w-node-b0d21b41-4354-aa8a-807b-b74f48934815-414c16ec,
#w-node-aae6c21c-dc3d-d0eb-9d54-ab1a0f1780ae-414c16ec, #w-node-b1fc84d2-548a-5cdd-668e-9c03877b88dd-414c16ec {
    grid-area: 1 / 3 / 2 / 7;
}
#w-node-_6b427139-4477-535d-4454-a2bf65a19b80-414c16ec, #w-node-e379db50-9912-5689-383a-b1ad65526706-414c16ec,
#w-node-e379db50-9912-5689-383a-b1ad65526706-414c16ec, #w-node-b0d21b41-4354-aa8a-807b-b74f48934816-414c16ec,
w-node-b1fc84d2-548a-5cdd-668e-9c03877b88de-414c16ec {
    grid-area: 1 / 1 / 2 / 3;
}
#w-node-a5b234e1-59b9-67ed-2f70-17b8238a269d-414c16ec, #w-node-ae15511b-c877-b7ed-acb0-cd7f5fc90300-414c16ec,
#w-node-_45c34909-759c-6f7e-b7a1-6e68794322b1-414c16ec, #w-node-_31b012fc-0c6b-64db-10e9-cd41bd4cbfb3-414c16ec {
    grid-area: 1 / 3 / 2 / 5;
    place-self: center end;
}
#w-node-_6b427139-4477-535d-4454-a2bf65a19b82-414c16ec, #w-node-_9505270e-ee6e-b22e-6c7c-38960143cc90-414c16ec,
#w-node-b0d21b41-4354-aa8a-807b-b74f48934818-414c16ec, #w-node-b1fc84d2-548a-5cdd-668e-9c03877b88e0-414c16ec{
    grid-area: 1 / 3 / 2 / 5;
}

.reference-section{
    background-color: white;
    color: black;
}

.head-line {
    background-color: var(--dddddd);
    position: relative;
    font-size: 4.66vw;
}
/*.head-line h2{
    font-size: 4.66vw;
}*/
.container-works {
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.2vw;
    display: flex;
}
.container-works.big-bottom {
    padding-bottom: 11vw;
}

.container-works.big-bottom.big-top {
    padding-top: 11vw;
}

.container-works.big-bottom.cst-top {
    padding-top: .6vw;
}

.legal-top-head {
    grid-column-gap: 1.2vw;
    grid-row-gap: 1.2vw;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-top: 12vw;
    padding-bottom: 0;
    display: flex;
}
.caps-16px {
    z-index: 16;
    color: var(--1e1e1f);
    text-transform: uppercase;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1vw;
    display: flex;
}
.line-2px {
    background-color: #cdcdcd;
    width: 100%;
    height: 2px;
}

.line-2px._1px {
    flex: none;
    height: 1.5px;
}


._6-col-grid {
    grid-column-gap: 1.2vw;
    grid-row-gap: 1.2vw;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    place-items: center start;
    width: 100%;
    display: grid;
}
.h-caps16 {
    grid-column-gap: .3vw;
    grid-row-gap: .3vw;
    display: flex;
}
.works-flex {
    grid-column-gap: .6vw;
    grid-row-gap: .6vw;
    flex-flow: column;
    width: 100%;
    display: flex;
}
.work-bg-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.work-bg-img.height {
    height: 21vw;
}

.selected-work {
    grid-column-gap: 1.2vw;
    grid-row-gap: 1.2vw;
    flex-flow: column;
    width: 100%;
    display: flex;
}
.work {
    color: var(--1e1e1f);
    text-decoration: none;
    position: relative;
    min-height: 48px;
}
.work .h1{
    white-space: nowrap;
}
.text-link {
    grid-column-gap: .3vw;
    grid-row-gap: .3vw;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: center;
    text-decoration: underline;
    display: flex;
    position: relative;
}
.work-img {
    opacity: 0;
    width: 26vw;
    display: block;
    position: absolute;
    inset: .6vw auto auto 0%;
}
.work-img.bottom {
    inset: auto auto -6vw 0%; /*auto auto 0% 0%*/
}
.work-image {
    background-color: var(--d0d0d0);
    color: var(--1e1e1f);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 31vw;
    text-decoration: none;
    display: flex;
    position: relative;
}

.work-image.height {
    color: var(--1e1e1f);
    height: 23vw;
    text-decoration: none;
}
.work-line {
    grid-column-gap: 0vw;
    grid-row-gap: 0vw;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    place-items: center start;
    width: 100%;
    display: grid;
}
.services-work {
    grid-column-gap: .7vw;
    grid-row-gap: .7vw;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.services-work.work-page {
    display: flex;
}

.services-link {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}
._6-col-grid {
    grid-column-gap: 1.2vw;
    grid-row-gap: 1.2vw;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    place-items: center start;
    width: 100%;
    display: grid;
}

.quadro {
    background-color: #1e9039;
    flex: none;
    width: .4vw;
    height: .4vw;
}

.quadro.black {
    background-color: var(--1e1e1f);
    flex: none;
    width: .3vw;
    height: .3vw;
}


@media screen and (max-width: 991px) {
    #w-node-a52dcbf1-9ec2-ed13-98a4-d72637d71f15-414c16ec {
        grid-column: 1 / 7;
    }
    #w-node-_6b427139-4477-535d-4454-a2bf65a19b7f-414c16ec, #w-node-b6852b0b-218a-194e-9b83-6f4d64ee456d-414c16ec,
    #w-node-bddb8431-8760-0334-0587-b45ea09037df-414c16ec, #w-node-_2a99fc00-3174-6759-81ff-47a34d8e3726-414c16ec,
    #w-node-b0d21b41-4354-aa8a-807b-b74f48934815-414c16ec, #w-node-aae6c21c-dc3d-d0eb-9d54-ab1a0f1780ae-414c16ec,
    #w-node-b1fc84d2-548a-5cdd-668e-9c03877b88dd-414c16ec, #w-node-_1f7e2bc9-83d7-fc65-45e1-15ba301b47b9-414c16ec,
    #w-node-_4c8096b4-4ab6-4c30-6f3b-4a6d6c1c6261-414c16ec, #w-node-_3cc5ceba-fa02-2ab1-404c-1ad4d91a88d6-414c16ec,
    #w-node-_7f73b380-fc56-35b3-1290-db01ca3ce741-414c16ec, #w-node-e0606907-4671-5144-3454-2d2e2cdc9209-414c16ec,
    #w-node-_1ab7170a-9e7d-970c-6a8d-d7caa437da43-414c16ec, #w-node-a7663047-e131-45aa-2b4a-8e85dceacaa0-414c16ec,
    #w-node-_72460fec-d1a9-d1df-e82c-a630974270fc-414c16ec, #w-node-c321a262-75cd-6159-303c-fe684d686491-414c16ec,
    #w-node-_18d4c7e5-0d36-abb9-38c3-95bd2f94d452-414c16ec {
        grid-column-start: 1;
    }
    #w-node-b0d21b41-4354-aa8a-807b-b74f48934816-414c16ec {
        grid-column-end: 4;
    }
    .container.big-bottom {
        padding-bottom: 20vw;
    }

    .container.big-bottom.big-top {
        padding-top: 20vw;
    }

    .container.big-bottom.cst-top {
        padding-top: 2.4vw;
    }
    .works-flex {
        grid-column-gap: 2.4vw;
        grid-row-gap: 2.4vw;
    }
    .work-image, .work-image.height {
        height: 55vw;
    }
    .work h1{
        padding-top: 3%;
        font-size: 4.6vw;
    }
    .work-img {
        opacity: 1;
        width: -webkit-fill-available;
        display: block;
        position: static;
    }
    .work-line {
        grid-column-gap: 2.4vw;
        grid-row-gap: 2.4vw;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
    }
    .services-work.work-page {
        grid-column-gap: 1.3vw;
        grid-row-gap: 1.3vw;
        display: flex;
    }
    .caps-16px {
        font-size: 1.9vw;
    }
    .quadro.black {
        aspect-ratio: 1;
        flex: none;
        width: .7vw;
        height: .7vw;
    }
}
@media screen and (max-width: 479px) {
    #w-node-a52dcbf1-9ec2-ed13-98a4-d72637d71f15-414c16ec {
        grid-column: 1 / 7;
    }

    .works-flex {
        grid-column-gap: 3.2vw;
        grid-row-gap: 3.2vw;
    }
    .work {
        grid-column-gap: 2.4vw;
        grid-row-gap: 2.4vw;
    }
    .services-work {
        grid-column-gap: 1.6vw;
        grid-row-gap: 1.6vw;
    }
    .caps-16px {
        font-size: 3.2vw;
    }
    .quadro.black {
        width: .85vw;
        height: .85vw;
    }

}
.work:hover .work-img,
.work:hover .services-link {
    opacity: 1 !important;
    transition: opacity 0.3s ease-in-out;
}

/*reviews*/
.review-section{

}
.review-header{
    padding: 12vw 1.2vw 1.2vw;
    font-size: 4.66vw;
}
.review-content{
    padding-top: 2.4vw;
    padding-bottom: 11vw;
}

.card {
    color: black;
    flex: 0 0 calc(93% / 3); /* Asztali nézethez */
    margin: 0 10px;
    background: #ddd;;
    max-width: 400px;
    height: 200px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
    transition: transform 0.3s ease;
}
/*blog*/
.blog{
    color: white;
    z-index: 80;
}
.blog a, .blog a:hover{
    border: white 1px solid;
    padding: 2%;
    color: white;
    text-decoration: none;
}
.blog-page{
    padding-top: 150px;
}
.post h1{
    font-size: 3.75rem;
}
.post h2{
    font-size: 1.565rem;
}
.post h4{
    font-size: 1.5625rem;
    margin: 30px 0 0;
}
.post-headline{
    margin-top: 40px;
}
.post-image{
    margin-top: 50px;
    margin-bottom: 50px;
}
.center{
    align-items: center;
}
.post-content p{
    font-size: 1.1875rem;
    line-height: 1.44em;
    margin-top: 19px;
    margin-bottom: 19px;
}
.bold{
    font-weight: 700;
}
/*appointment*/
.appointment-page{
    margin-top: 150px;
    margin-bottom: 150px;
}
.appointment-page h1{
    font-size: 48px;
}
.appointment-page h2{
    margin-top: 32px;
}
.appointment-page p{
    font-size: 1.1875rem;
    line-height: 1.44em;
    margin-top: 19px;
    margin-bottom: 19px;
}
.appointment-page a, .appointment-page a:hover{
    color: white;

}
.book-btn, .time-btn {
    border: 1px solid #54abc9;
    height: 60px;
}
.book-btn {
    width: 250px;
}
.time-btn {
    width: 100px;
}
/*footer*/
footer{
    position: relative;
    color: white;
}
footer a, footer a:hover{
    color: white;
}
footer ul{
    list-style-type: none;
}
footer li{
    display: flex;
    margin: 10px;
}
.message-button, .service-cta {
    align-items: center;
    background: white; /*linear-gradient(174deg, #b2262a, #e64a2c);*/
    border-radius: 8px;
    font-size: 1.5rem;
    padding: 15px;
    cursor: pointer;
}


div.FooterCover_glareContainer {
    position: absolute;
    width: 140%;
    height: 100%;
    left: -20%;
    top: 0%;
}
/*cookie*/
.cookie-settings{
    order: 1;
    /*z-index: 90;*/
    align-self: flex-end;
    position: relative;
    bottom: 00px;
    right: 0px;
    background-color: #112724;
}

.cookie-banner{
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: black;
    color: white;
    z-index: 1000;
    padding: 1%;
}
.cookie-banner-header{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}
.cookie-banner-title{
    color: white;
}
.cookie-banner-description{
    color: white;
}
.cookie-buttons{
    display: flex;
    gap: 2rem;
}
.cookie-options{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.cookie-banner button{
    font-family: Roboto, serif;
    font-size: 0.9rem;
    padding: 6px 12px;
    background-color: white;
    color: black;
    border: 4px solid white;
    border-radius: 10px;
    cursor: pointer;
}